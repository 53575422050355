<template>
    <div>
        <landing-header></landing-header>
        <main class="p-3 static-page">
            <div class="terms-hero-heading text-center m-3 p-3">
                <h3>TERMS AND CONDITIONS OF CARRIAGE</h3>
            </div>
            <section>
                <h5>Important Notice</h5>
                <p>
                    When ordering FTD Logistics services you, as “Shipper”, are agreeing, on your behalf and on behalf
                    of the receiver of the Shipment (“Receiver”) and anyone else with an interest in the Shipment that
                    these Terms and Conditions shall apply
                </p>
                <br>
                <p>
                    “Shipment” means all documents or parcels that travel under one waybill and which may be
carried by any means FTD Logistics chooses, including air, road or any other carrier. A “waybill”
shall include any Shipment identifier or document produced by FTD Logistics or Shipper automated
systems such as a label, barcode, waybill or consignment note as well as any electronic version
thereof. Every Shipment is transported on a limited liability basis as provided herein. If Shipper
requires greater protection, then insurance may be arranged at an additional cost. (Please see
below for further information). “FTD” means any member of the FTD Logistics Team.
                </p>
                <ol>
                    <li>
                        <b>Customs Clearance</b>
                        <p>
                            FTD Logistics may perform any of the following activities on Shipper’s or Receiver’s behalf in order
to provide its services:
                        </p>
                        
                            <p>
                                i. Complete any documents, amend product or service codes, and pay any duties,
    taxes or penalties required under applicable laws and regulations (“Customs Duties”),
                            </p>
<p>
    ii. Act as Shipper’s forwarding agent for customs and export control purposes
    and as Receiver solely for the purpose of designating a customs broker to perform
    customs clearance and entry and
</p>
<p>
    iii. Redirect the Shipment to Receiver’s customs broker or other address upon request
    by any person who FTD Logistics believes in its reasonable opinion to be authorized.
</p>
                    </li>
                    <li>
                        <b>Unacceptable Shipments</b>
                        <p>A Shipment is deemed unacceptable if:</p>
<p>
    i. No customs declaration is made when required by applicable customs regulations,
</p>
<p>
    ii. It contains counterfeit goods, animals, bullion, currency, gemstones; weapons,
    explosives and ammunition; human remains; illegal items, such as ivory and narcotics,
</p>
<p>
    iii. It is classified as hazardous material, dangerous goods, prohibited or restricted
    articles by IATA (International Air Transport Association), ICAO (International Civil Aviation
    Organization), ADR (European Road Transport Regulation on dangerous goods) or
    other relevant organization (“Dangerous Goods”),
</p>
<p>
    iv. Its address is incorrect or not properly marked or its packaging is defective or
    inadequate to ensure safe transportation with ordinary care in handling,
</p>
<p>v. It contains any other item which FTD Logistics decides cannot be carried safely or
legally
                        </p>
                    </li>
                    <li>
                        <b>Deliveries and Undeliverable</b>
                        <p>
                            Shipments cannot be delivered to PO boxes or postal codes. Shipments are delivered to the
Receiver’s address given by Shipper but not necessarily to the named Receiver personally.
Shipments to addresses with a central receiving area will be delivered to that area.

                        </p>
                        <p>
                            FTD Logistics may notify the Receiver of an upcoming delivery or a missed delivery. Receivers may
be offered alternative delivery options such as delivery on another day, no signature required,
redirection or collection at a FTD Logistics Outstation. Shippers may exclude some delivery options
on request.

                        </p>
                        <p>
                            If the Shipment is deemed to be unacceptable as described in Section 2, or it has been
undervalued for customs purposes, or Receiver cannot be reasonably identified or located,
or Receiver refuses delivery or to pay Customs Duties or other Shipment charges, FTD Logistics
shall use reasonable efforts to return the Shipment to Shipper at Shipper’s cost, failing which the
Shipment may be released, disposed of or sold without incurring any liability whatsoever to
Shipper or anyone else, with the proceeds applied against Customs Duties, Shipment charges and
related administrative costs with the balance of the proceeds of a sale to be returned to Shipper.
FTD Logistics shall have the right to destroy any Shipment which any law prevents FTD Logistics
from returning to Shipper as well as any Shipment of Dangerous Goods.

                        </p>
                    </li>
                    <li>
                        <b>Inspection</b>
                        <p>
                            FTD Logistics has the right to open and inspect a Shipment without notice for safety, security,
customs or other regulatory reasons.
                        </p>
                    </li>
                    <li>
                        <b>Shipment Charges and Fees</b>
                        <p>FTD Logistics’ Shipment charges are calculated according to the higher of actual or volumetric
weight per piece and any piece may be re-weighed and re-measured by FTD Logistics to confirm
this calculation.
</p>
                        <p>Shipper, or the Receiver when FTD Logistics acts on Receiver’s behalf, shall pay or reimburse FTD
Logistics for all Shipment or other charges due, or Customs Duties owed for services provided by
FTD Logistics or incurred by FTD Logistics on Shipper’s or Receiver’s behalf. Payment of Customs
Duties may be requested prior to delivery.
</p>
                    </li>
                    <li>
                        <b>FTD Logistics Liability</b>
                        <p>
                            6.1 FTD Logistics liability is strictly limited to direct loss and damage to a Shipment only and to
the per kilogram limits. All other types of loss or damage are excluded (including but not
limited to lost profits, income, interest, future business), whether such loss or damage is special
or indirect, and even if the risk of such loss or damage was brought to FTD Logistics
attention.
                        </p>
                        <p>
                            FTD Logistics will make every reasonable effort to deliver the Shipment according to FTD
Logistics regular delivery schedules, but these schedules are not binding and do not form
part of the contract. FTD Logistics is not liable for any damages or loss caused by delay, but
for certain Shipments, Shipper may be able to claim limited delay compensation under the
Money Back Guarantee terms and conditions.
                        </p>
                    </li>
                    <li>
                        <b>Claims</b>
                        <p>
                            All claims must be submitted in writing to FTD Logistics within thirty (30) days from the
date that FTD Logistics accepted the Shipment, failing which FTD Logistics shall have no liability
whatsoever. Claims are limited to one claim per Shipment, settlement of which will be full and final
settlement for all loss or damage in connection therewith.

                        </p>
                    </li>
                    <li>
                        <b>Shipment Insurance</b>
                        <p>
                            FTD Logistics may be able to arrange insurance covering the value in respect of loss of or damage
to the Shipment, provided that the Shipper so instructs FTD Logistics in writing, including by
completing the insurance section on the front of the waybill or by FTD logistics’ automated
systems and pays the applicable premium. Shipment insurance does not cover indirect loss or
damage, or loss or damage caused by delays.

                        </p>
                    </li>
                    <li>
                        <b>Circumstances Beyond FTD Logistics Control</b>
                        <p>
                            FTD logistics is not liable for any loss or damage arising out of circumstances beyond FTD
Logistics’ control. These include but are not limited to electrical or magnetic damage to, or erasure
of, electronic or photographic images, data or recordings; any defect or characteristic related to the
nature of the Shipment, even if known to FTD Logistics; any act or omission by a person not
employed or contracted by FTD Logistics - e.g. Shipper, Receiver, third party, customs or other
government official; “Force Majeure” - e.g. earthquake, cyclone, storm, flood, fog, war, plane
crash, embargo, riot, civil commotion, or industrial action.

                        </p>
                    </li>
                    <li>
                        <b>Shipper’s Warranties and Indemnities</b>
                        <p>
                            Shipper shall indemnify and hold FTD logistics harmless for any loss or damage arising out of
Shipper’s failure to comply with the following warranties and representations:
                        </p>
                        <p>
                            • all information provided by Shipper or its representatives is complete and accurate.
    • The Shipment is acceptable for transport under Section 2 above.
                        </p>
<p>
    • The Shipment was prepared in secure premises by reliable persons and was protected against
    unauthorized interference during preparation, storage and any transportation to FTD Logistics.
</p>
<p>
    • Shipper has complied with all applicable customs, import, export, data protection laws,
    sanctions, embargoes and other laws and regulations; and
</p>
<p>
    • Shipper has obtained all necessary consents in relation to personal data provided to FTD
    Logistics including Receiver’s data as may be required for transport, customs clearance and
    delivery, such as e-mail address and mobile phone number.
</p>
                    </li>
                    <li>
                        <b>Routing</b>
                        <p>Shipper agrees to all routing and diversion, including the possibility that the Shipment may
be carried via intermediate stopping places.
</p>
                    </li>
                    <li>
                        <b>Governing Law</b>
                        <p>
                            Any dispute arising under or in any way connected with these Terms and Conditions shall be
subject, for the benefit of FTD Logistics, to the non-exclusive jurisdiction of the courts of, and
governed by the law of the country of origin of the Shipment and Shipper irrevocably
submits to such jurisdiction, unless contrary to applicable law
                        </p>
                    </li>
                    <li>
                        <b>Severability</b>
                        <p>
                            The invalidity or unenforceability of any provision shall not affect any other part of these Terms
and Conditions.
                        </p>
                    </li>
                </ol>
            </section>
        </main>
        <landing-footer></landing-footer>
    </div>
</template>
<script>
import Footer from '../layout/footer.vue'
import header from "../layout/header.vue"

export default{
    components: { landingHeader: header, landingFooter: Footer }, 
    name: "GeneralTerms",
}
</script>